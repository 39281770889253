exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ndc-index-jsx": () => import("./../../../src/pages/ndc/index.jsx" /* webpackChunkName: "component---src-pages-ndc-index-jsx" */),
  "component---src-pages-netwrix-index-jsx": () => import("./../../../src/pages/netwrix/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-index-jsx" */),
  "component---src-pages-privilege-secure-index-jsx": () => import("./../../../src/pages/privilege-secure/index.jsx" /* webpackChunkName: "component---src-pages-privilege-secure-index-jsx" */)
}

